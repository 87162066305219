<template>
  <modal name="modal-admin-order-alarm-log" class="modal-inner modal-admin-order-alarm-log" transition="pop-out" :width="modalWidth" :focus-trap="true" :min-height="600" height="auto" :scrollable="true" :resizable="true" :adaptive="true" :reset="true">
    <span class="close-button" @click="hide('modal-admin-order-alarm-log')"><BaseIcon icon="times-circle" class="text-white"/></span>
    <div class="modal-header">
      Beställ
    </div>
    <div class="modal-body text-sm" v-if="log_info">
      <div class="clear-both py-2">
        <div class="float-left font-semibold">
          Kund:
        </div>
        <div class="float-right">
          {{ getCustomerName(log_info.customer_id) }}
        </div>
      </div>
      <div class="clear-both py-2">
        <div class="float-left font-semibold">
          Verksamhetsnamn:
        </div>
        <div class="float-right">
          {{ getUnitName(log_info.unit_id) }}
        </div>
      </div>

      <div class="clear-both border-b border-secondary-400 pt-4 mb-3"></div>

      <div class="clear-both py-2">
        <div class="float-left font-bold">
          Beställarens uppgifter
        </div>
      </div>
      <div class="clear-both py-2">
        <div class="float-left font-semibold">
          Namn:
        </div>
        <div class="float-right">
          {{ log_info.customer_info.contact_name }}
        </div>
      </div>
      <div class="clear-both py-2">
        <div class="float-left font-semibold">
          E-post:
        </div>
        <div class="float-right">
          {{ log_info.customer_info.contact_email }}
        </div>
      </div>
      <div class="clear-both py-2">
        <div class="float-left font-semibold">
          Telefonnummer:
        </div>
        <div class="float-right">
          {{ log_info.customer_info.contact_phone }}
        </div>
      </div>
      <div class="clear-both py-2">
        <div class="float-left font-semibold">
          Företagsnamn:
        </div>
        <div class="float-right">
          <pre class="font-sans whitespace-pre-line">{{ log_info.customer_info.company_name }}</pre>
        </div>
      </div>
      <div class="clear-both py-2">
        <div class="float-left font-semibold">
          Fakturaadress:
        </div>
        <div class="float-right">
          <pre class="font-sans whitespace-pre-line">{{ log_info.customer_info.invoice_detail }}</pre>
        </div>
      </div>

      <div class="clear-both border-b border-secondary-400 pt-4 mb-3"></div>

      <div class="clear-both py-2">
        <div class="float-left font-bold">
          Generella frågor
        </div>
      </div>
      <div class="clear-both py-2">
        <div class="float-left">
          Sker en del av arbetet utomhus, eller genom hembesök?
        </div>
        <div class="float-right">
          {{ log_info.customer_info.outdoor_work }}
        </div>
      </div>
      <div class="clear-both py-2">
        <div class="float-left">
          Vill ni kunna larma mellan kollegor?
        </div>
        <div class="float-right">
          {{ log_info.customer_info.alert_members }}
        </div>
      </div>
      <div class="clear-both py-2">
        <div class="float-left">
          Vill ni veta vart i huset man befinner sig vid larmning?
        </div>
        <div class="float-right">
          {{ log_info.customer_info.indoor_tracking }}
        </div>
      </div>
      <div class="clear-both py-2">
        <div class="float-left">
          Vill ni att larm ska aktiveras om personen som bär larmet ramlar (Mandown)?
        </div>
        <div class="float-right">
          {{ log_info.customer_info.man_down_alert }}
        </div>
      </div>
      <div class="clear-both py-2">
        <div class="float-left">
          Vill ni få SMS eller Mejl om ett larm har aktiverats:
        </div>
        <div class="float-right">
          <pre class="font-sans whitespace-pre-line">{{ log_info.customer_info.email_sms_detail }}</pre>
        </div>
      </div>

      <div class="clear-both border-b border-secondary-400 pt-4 mb-3"></div>

      <div class="clear-both py-2">
        <div class="float-left font-bold">
          Larmunderlag
        </div>
      </div>
      <div class="clear-both py-2">
        <div class="float-left">
          Antal personer som ska använda larmet:
        </div>
        <div class="float-right">
          {{ log_info.customer_info.total_user }}
        </div>
      </div>
      <div class="clear-both py-2">
        <div class="float-left">
          Vad heter personen/gruppen?
        </div>
        <div class="float-right">
          {{ log_info.customer_info.person_group_names }}
        </div>
      </div>
      <div class="clear-both py-2">
        <div class="float-left">
          Personens/ gruppens arbetsuppgifter:
        </div>
        <div class="float-right">
          {{ log_info.customer_info.work_task }}
        </div>
      </div>
      <div class="clear-both py-2">
        <div class="float-left">
          Arbetsplatsens ort:
        </div>
        <div class="float-right">
          {{ log_info.customer_info.working_place }}
        </div>
      </div>
      <div class="clear-both py-2">
        <div class="float-left">
          Finns det idag någon hot- eller riskbild som du vill att larmcentralen skall veta?
        </div>
        <div class="float-right">
          {{ log_info.customer_info.risk_detail }}
        </div>
      </div>
      <div class="clear-both py-2">
        <div class="float-left">
          Övrig information:
        </div>
        <div class="float-right">
          <pre class="font-sans whitespace-pre-line">{{ log_info.customer_info.other_info }}</pre>
        </div>
      </div>

      <div class="clear-both border-b border-secondary-400 pt-4 mb-3"></div>

      <div class="clear-both pt-2">
        <div class="float-left font-semibold">
          Säljare:
        </div>
        <div class="float-right">
          {{ getSellerName(log_info.seller) }}
        </div>
      </div>
      <div class="clear-both pt-2">
        <div class="float-left font-semibold">
          Larmcentral:
        </div>
        <div class="float-right">
          {{ getAlarmCenterName(log_info.alarm_center) }}
        </div>
      </div>
      <div class="clear-both pt-2">
        <div class="float-left font-semibold">
          Simkort:
        </div>
        <div class="float-right">
          {{ log_info.sim_card }}
        </div>
      </div>

      <div class="clear-both border-b border-secondary-400 pt-4 mb-3"></div>

      <div class="clear-both py-2">
        <div class="float-left font-semibold">
          Avtalsnummer:
        </div>
        <div class="float-right font-bold">
          {{ log_info.agreement_number }}
        </div>
      </div>
      <div class="clear-both py-2">
        <div class="float-left font-semibold">
          Avtalsperiod:
        </div>
        <div class="float-right">
          {{ log_info.contract_period }}
        </div>
      </div>
      <div class="clear-both pt-2">
        <div class="float-left font-semibold">
          Uppläggningsavgift:
        </div>
        <div class="float-right">
          {{ log_info.setup_fee }}
        </div>
      </div>
      <div class="clear-both pt-2">
        <div class="float-left font-semibold">
          Hyreskostnad:
        </div>
        <div class="float-right">
          {{ log_info.rental_month_cost }}
        </div>
      </div>
      <div class="clear-both py-2">
        <div class="float-left font-semibold">
          Betalningsvillkor:
        </div>
        <div class="float-right">
          {{ log_info.payment_terms }}
        </div>
      </div>
      <div class="clear-both py-2">
        <div class="float-left font-semibold">
          Avtalsstart:
        </div>
        <div class="float-right">
          {{ log_info.contract_start }}
        </div>
      </div>

      <div class="clear-both py-2">
        <div class="float-left font-semibold">
          Särskilda villkor:
        </div>
        <div class="float-right">
          <pre class="font-sans whitespace-pre-line">{{ log_info.special_terms }}</pre>
        </div>
      </div>
      <div class="clear-both py-2">
        <div class="float-left font-semibold">
          När behöver beställningen vara klar:
        </div>
        <div class="float-right">
          <pre class="font-sans whitespace-pre-line">{{ log_info.delivery_period }}</pre>
        </div>
      </div>

      <div class="clear-both border-b border-secondary-400 pt-4 mb-3"></div>

      <div class="clear-both py-2">
        <div class="float-left font-semibold">
          Riskprofil:
        </div>
        <div class="float-right">
          {{ log_info.risk_profile }}
        </div>
      </div>
      <div class="clear-both py-2">
        <div class="float-left font-semibold">
          Utgår från adress:
        </div>
        <div class="float-right">
          {{ log_info.from_address }}
        </div>
      </div>
      <div class="clear-both pt-2">
        <div class="float-left font-semibold">
          Namn på produkter:
        </div>
        <div class="float-right">
          <pre class="font-sans whitespace-pre-line">{{ log_info.alarms_name }}</pre>
        </div>
      </div>
      <div class="clear-both py-2">
        <div class="float-left font-semibold">
          Kommentar till orderhantering:
        </div>
        <div class="float-right">
          <pre class="font-sans whitespace-pre-line">{{ log_info.comment }}</pre>
        </div>
      </div>

      <div class="clear-both border-b border-secondary-400 pt-4 mb-3"></div>

      <div class="clear-both py-2">
        <div class="float-left font-semibold">
          Information SecurCloud:
        </div>
        <div class="float-right">
          <pre class="font-sans whitespace-pre-line">{{ log_info.info_securcloud }}</pre>
        </div>
      </div>
      <div class="clear-both py-2">
        <div class="float-left font-semibold">
          Kontaktpersoner:
        </div>
        <div class="float-right">
          <pre class="font-sans whitespace-pre-line">{{ log_info.contact_person }}</pre>
        </div>
      </div>
      <div class="clear-both py-2">
        <div class="float-left font-semibold">
          Lösenord:
        </div>
        <div class="float-right">
          {{ log_info.alarm_password }}
        </div>
      </div>

      <div class="clear-both border-b border-secondary-400 pt-4 mb-3"></div>

      <div class="clear-both py-2">
        <div class="float-left font-semibold">
          Internlarm
        </div>
        <div class="float-right">
          <BaseIcon v-if="log_info.call_button" icon="check-circle" class="text-accent-500" />
          <BaseIcon v-else icon="times-circle" class="text-red-500" />
        </div>
      </div>
      <div class="clear-both py-2">
        <div class="float-left font-semibold">
          Timeralarm
        </div>
        <div class="float-right">
          <BaseIcon v-if="log_info.timer_alarm" icon="check-circle" class="text-accent-500" />
          <BaseIcon v-else icon="times-circle" class="text-red-500" />
        </div>
      </div>
      <div class="clear-both py-2">
        <div class="float-left font-semibold">
          Mandown
        </div>
        <div class="float-right">
          <BaseIcon v-if="log_info.man_down" icon="check-circle" class="text-accent-500" />
          <BaseIcon v-else icon="times-circle" class="text-red-500" />
        </div>
      </div>
      <div class="clear-both py-2">
        <div class="float-left font-semibold">
          9-Vibb
        </div>
        <div class="float-right">
          <BaseIcon v-if="log_info.nine_vib" icon="check-circle" class="text-accent-500" />
          <BaseIcon v-else icon="times-circle" class="text-red-500" />
        </div>
      </div>
      <div class="clear-both py-2">
        <div class="float-left font-semibold">
          SMS:
        </div>
        <div class="float-right">
          <div v-for="(n, i) in log_info.sms_numbers" :key="i">
            {{ n.msisdn }}
          </div>
        </div>
      </div>

      <div class="clear-both border-b border-secondary-400 pt-4 mb-3"></div>

      <template v-for="p in log_info.products">
        <div v-bind:key="p.id" class="clear-both py-2" v-if="p.qty > 0">
          <div class="float-left font-semibold">
            {{ p.name }}
          </div>
          <div class="float-right">{{ p.qty }} * {{ p.price }}</div>
        </div>
      </template>

      <div class="clear-both border-b border-secondary-400 pt-4 mb-3"></div>

      <div class="clear-both py-2">
        <div class="float-left font-semibold">
          Pipedrive deal
        </div>
        <div class="float-right">
          <BaseIcon v-if="log_info.pipedrive_deal_id != null" icon="check-circle" class="text-accent-500" />
          <BaseIcon v-else icon="times-circle" class="text-red-500" />
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
const MODAL_WIDTH = 800;

export default {
  name: "adminOrderAlarmLogModal",
  props: {
    log_info: {
      required: true,
      default: null,
    },
    customers: {
      required: true,
      default: [],
    },
    units: {
      required: true,
      default: null,
    },
    sellers: {
      required: true,
      default: [],
    },
    alarm_centers: {
      required: true,
      default: [],
    },
  },

  data() {
    return {
      sim_operators: ["Telia", "Telia DCP", "Telenor", "Tele2", "Kundens Simkort"],
    };
  },

  computed: {
    user() {
      return this.$store.state.user;
    },
  },

  methods: {
    getCustomerName(customer_id) {
      let c = _.find(this.customers, { customer_id: customer_id });
      if (c) return c.name;
      else return "";
    },

    getUnitName(unit_id) {
      let u = _.find(this.units, { unit_id: unit_id });
      if (u) return u.name;
      else return "";
    },

    getSellerName(seller_id) {
      let s = _.find(this.sellers, { _id: seller_id });
      if (s) return s.name;
      else return "";
    },

    getAlarmCenterName(alarm_center_id) {
      let a = _.find(this.alarm_centers, { _id: alarm_center_id });
      if (a) return a.name;
      else return "";
    },

    showAdminOrderLogModal(log) {
      this.log_info = log;
      this.$modal.show("modal-admin-order-alarm-log");
    },

    show(id) {
      this.$modal.show(id);
    },

    hide(id) {
      this.$modal.hide(id);
    },
  },

  created() {
    this.modalWidth = window.innerWidth < MODAL_WIDTH ? window.innerWidth : MODAL_WIDTH;
  },
};
</script>
